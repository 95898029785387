.verify-form .form {
}
.verify-form .form__message-wrapper {
  margin-bottom: 12px;
}
.verify-form .form__message {
  font-size: 15px;
}

.verify-form .form__input-wrapper {
  display: flex;
  flex-direction: column;
}
.verify-form .form__input-label {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 2px;
}
.verify-form .form__input {
  border-radius: 6px;
  border: 1px solid #ced4da;
  padding: 8px 12px;
}
.verify-form .form__button-wrapper {
  text-align: center;
  margin: 18px 0px;
}
.verify-form .form__button {
  padding: 6px 16px;
  font-weight: 600;
  border-radius: 6px;
  border-color: transparent;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: all 0.2s ease-in;
  font-size: 15px;
}
.verify-form .form__button:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #000000;
}
.verify-form .form__resend-wrapper {
  display: flex;
  justify-content: center;
}
.verify-form .form__resend-button {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  color: #696969;
  font-size: 14px;
}

.verify-form .form__resend-button:hover {
  color: #000000;
}
.verify-form .form__resend-button:disabled {
  color: #b0b0b0;
  cursor: not-allowed;
}

.verify-form .form__countdown {
  font-size: 14px;
}
